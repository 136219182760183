import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const SHikiTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "作業者", value: "worker" },
  { text: "機械名", value: "machineCode" },
  { text: "機械点検記録", value: "inspectionFlag" },
  { text: "抜き取り(mm)", value: "extraction" },
  { text: "仕上数 20万m/147kg(0.32)", value: "finish20km" },
  { text: "仕上数10万m/149kg(0.45)", value: "finish10km" },
  { text: "仕上数8万m/118kg(0.45)", value: "finish8km" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class SHikiTable {
  workDate: string;
  updateTs: string | null;
  machineCode: string | null;
  worker: string | null;
  inspectionFlag: boolean;
  extraction: number | null;
  finish20km: number | null;
  finish10km: number | null;
  finish8km: number | null;
  abnormality: string | null;
  biko: string | null;

  constructor(props: {
    workDate: string;
    machineCode: string | null;
    worker: string | null;
    inspectionFlag: string | null;
    extraction: number | null;
    finish20km: number | null;
    finish10km: number | null;
    finish8km: number | null;
    abnormality: string | null;
    biko: string | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.machineCode = props.machineCode;
    this.worker = props.worker;
    if (props.inspectionFlag === "1") {
      this.inspectionFlag = true;
    } else {
      this.inspectionFlag = false;
    }
    this.extraction = props.extraction;
    this.finish20km = props.finish20km;
    this.finish10km = props.finish10km;
    this.finish8km = props.finish8km;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
  }
}

export const SHikiText = {
  workDate: { text: "作業年月日", value: "workDate" },
  machineCode: { text: "機械名", value: "machineCode" },
  worker: { text: "作業者", value: "worker" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  extraction: { text: "抜き取り(mm)", value: "extraction" },
  finish20km: { text: "仕上数20万m/147kg(0.32)", value: "finish20km" },
  finish10km: { text: "仕上数10万m/149kg(0.45)", value: "finish10km" },
  finish8km: { text: "仕上数8万m/118kg(0.45)", value: "finish8km" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class SHiki extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  worker: string | null;
  inspectionFlag: string | null;
  extraction: number | null;
  finish20km: number | null;
  finish10km: number | null;
  finish8km: number | null;
  abnormality: string | null;
  biko: string | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: SHiki);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: SHiki | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof SHiki) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.worker = _props.worker;
      this.inspectionFlag = _props.inspectionFlag;
      this.extraction = _props.extraction;
      this.finish20km = _props.finish20km;
      this.finish10km = _props.finish10km;
      this.finish8km = _props.finish8km;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "SHIKI";
    this.machineCode = null;
    this.worker = null;
    this.inspectionFlag = "0";
    this.extraction = null;
    this.finish20km = null;
    this.finish10km = null;
    this.finish8km = null;
    this.abnormality = null;
    this.biko = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
